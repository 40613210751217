import React from "react";
import "./Home.css";
function Home() {
    return (
      <div id="home">
        <div>MUHAMMAD HARIS</div>
        <div>Software Engineer</div>
      </div>
    );
  }
  
  export default Home;
  